<template lang="pug">
  section#advantages.section.advantages
    div.container
      div.advantages__content.content
        h2.h2.advantages__title Main advantages of <br> our short link service
        div.advantages__text.text With F4.TO, create your own network of links that are easily controllable from one place.

      div.advantages__cards
        div.cards__item
          div.cards__img
            img(src="~@/assets/images/advantages-1.svg" alt="advantages icon")
          h3.h3.card__title Easy-to-use
          div.card__text.text The service has a simple structure - paste a long link and get a shortened one. Make your links look better and easier to share.

        div.cards__item
          div.cards__img
            img(src="~@/assets/images/advantages-2.svg" alt="advantages icon")
          h3.h3.card__title Individual analytics
          div.card__text.text View detailed graphs for each metric. Customize your analytics <br> for different needs and goals.

        div.cards__item
          div.cards__img
            img(src="~@/assets/images/advantages-3.svg" alt="advantages icon")
          h3.h3.card__title Secure link redirection
          div.card__text.text All links are protected by data encryption and HTTPS protocols. Use secure tools with confidence.

        div.cards__item
          div.cards__img
            img(src="~@/assets/images/advantages-4.svg" alt="advantages icon")
          h3.h3.card__title Traffic tracking
          div.card__text.text Access up-to-date information on the number of clicks and traffic sources. Improve your future strategy for further work and marketing.

        div.cards__item
          div.cards__img
            img(src="~@/assets/images/advantages-5.svg" alt="advantages icon")
          h3.h3.card__title Phishing protection
          div.card__text.text Block links to spam and unwanted services. Create an additional level of security for users.

        div.cards__item
          div.cards__img
            img(src="~@/assets/images/advantages-6.svg" alt="advantages icon")
          h3.h3.card__title Personalized domains
          div.card__text.text Create personalized branded links for your company or brand. Build a strong image that customers can trust.
</template>

<script>

export default {
  name: 'AdvantagesBlock'
}

</script>
